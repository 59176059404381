import React from 'react';
import { Svg } from 'assets';
import { NavLink } from 'react-router-dom';

export const Footer = () => {

  return (
    <footer className='footer-wrapper'>
      <div className='container'>
        <Svg.Logo className='social-logo-dp' />

        <div className='footer-block'>
          <div className='footer-lists logo-and-social'>
            <Svg.Logo className='social-logo-1440' />

            <div className='social'>
              <p className='follow-us'>
                Follow us
              </p>
              <a
                href='https://www.facebook.com/'
                target='_blank'
                rel='noreferrer'
                className='social-link'
              >
                <Svg.Facebook className='social-svgs' />
              </a>

              <a
                href='https://am.linkedin.com/'
                target='_blank'
                rel='noreferrer'
                className='social-link'
              >
                <Svg.Linkedin className='social-svgs' />
              </a>

              <a
                href='https://www.instagram.com/'
                target='_blank'
                rel='noreferrer'
                className='social-link'
              >
                <Svg.Instagram className='social-svgs' />
              </a>
            </div>

          </div>

          <div className='footer-lists footer-search'>
            <p className='footer-lists-title'>
              Search
            </p>
            <div className='footer-lists-item'>
              <NavLink to='order-now'
              >
                <p className='footer-lists-item-text'>
                  Get FRV Report
                </p>
              </NavLink>
            </div>

            <div className='footer-lists-item'>
              <NavLink to='/#work-with-us'>
                <p className='footer-lists-item-text'>
                  Why Work With Us
                </p>
              </NavLink>
            </div>

          </div>

          <div className='footer-lists footer-company'>
            <p className='footer-lists-title'>
              Company
            </p>

            <div className='footer-lists-item'>
              <NavLink to='terms'
              >
                <p className='footer-lists-item-text'>
                  Terms of Use
                </p>
              </NavLink>
            </div>

            <div className='footer-lists-item'>
              <NavLink to='privacy'
              >
                <p className='footer-lists-item-text'>
                  Privacy Policy
                </p>
              </NavLink>
            </div>
          </div>

          <div className="footer-lists footer-contact">
            <p className="footer-lists-title">
              Contact Us
            </p>

            <div className="footer-lists-item">
              <Svg.Sms className="footer-lists-item-svg" />

              <a href="mailto:claimfrv@gmail.com" className="footer-lists-item-text">
                claimfrv@gmail.com
              </a>
            </div>

            <div className="footer-lists-item">
              <Svg.CallSvg className="footer-lists-item-svg" />

              <a href="tel:+8338922970" className="footer-lists-item-text">
                (833) 892-2970
              </a>
            </div>

            <div className="footer-lists-item">
              <Svg.Messages className="footer-lists-item-svg" />

              <NavLink to="contact-us">
                <p className="footer-lists-item-text" style={{ textDecoration: 'underline' }}>
                  Send Us Message
                </p>
              </NavLink>
            </div>

            <div className="footer-lists-item">
              <Svg.LocationSvg className="footer-lists-item-svg" />

              <a

                  target={'_blank'}
                  href={`https://www.google.com/maps?saddr=My+Location&daddr=37.433745,-122.128673`}
                  rel="noreferrer"
                  className="footer-lists-item-text">
                2652 Middlefield Rd. #451, Palo Alto, Silicon Valley, CA 94306
              </a>
            </div>
          </div>
        </div>

        <div className="patent-pending_reserved">
          <p className="patent-pending">
            Patent Pending
          </p>

          <p className="reserved">
            ©{new Date().getFullYear()} ClaimFRV. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

