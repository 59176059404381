import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { ordersActions } from 'store';
import { ButtonMain, FadeLoaderC } from 'components';
import { CardReport } from './fragments/cardReport';
import { FindLoad, useWindowSize } from 'utils';
import { Svg, Images } from 'assets';

const ACTION_TYPE = 'SINGLE_ORDERS_REQUEST';

export const FrvReport = () => {
    const [downloadLoad, setDownloadLoad] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const singleOrder = useSelector((state) => state.orders.singleOrder);
    const loading = FindLoad(ACTION_TYPE);
    const [width] = useWindowSize();


    useEffect(() => {
        dispatch(ordersActions.ordersPost(params?.id));
    }, [dispatch]);

    const handleDownload = async (item) => {
        setDownloadLoad(true);
        try {
            const response = await axios({
                url: `/files/download`,
                method: 'POST',
                responseType: 'blob',
                data: item,
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const fileIndex = response?.data?.type?.search('/');
            const fileType = response?.data?.type?.slice(fileIndex + 1);
            let filename = item?.originalName ? `${singleOrder?.report?.address}-frv.pdf` : `file.${fileType === 'svg+xml' ? 'svg' : fileType}`;


            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } finally {
            setDownloadLoad(false);
        }
    };


    if (loading) {
        return (
            <div className="rental-loading-wrapper">
                <FadeLoaderC big />
                <p className="rental-loading-text">Generating Your FRV Report...</p>

                <p className="rental-loading-wait">Please wait a moment while we process your request. Your report will
                    be ready
                    shortly.</p>
            </div>
        );
    }


    return (
        <div className="rental-page">
            <div className="container">
                {singleOrder?.id ? (
                    <div className="content-button-block">
                        <div className="button-svg_block_mobile">
                            <Svg.CloseSvg className="button-svg_mobile" onClick={() => navigate('/')} />
                        </div>

                        <div className="rental-wrapper">
                            <div className="rental-screen-1">
                                <div className="rental-container">
                                    <div className="header-logo">
                                        <Svg.Logo className="header-logo-svg" />

                                        <div className="header-logo-temp">
                                            <img src={Images.TempLogo} alt="logo" />
                                        </div>
                                    </div>

                                    <div className="info_date-claim">
                                        <p className="info_date">
                                            Date
                                            Created: {moment(singleOrder?.createdAt).format('MM/DD/YYYY hh:mm A') || 'Not Set'}
                                        </p>

                                        <p className="info_date">
                                            Claim #: {singleOrder?.claimNumber || 'Not Set'}
                                        </p>
                                    </div>

                                    <div className="rental-title">
                                        Fair Rental Value Report
                                    </div>

                                    <div className="subject-property">
                                        <div className="subject-property-title">
                                            Subject Property Details
                                        </div>

                                        <div className="subject-property-info margin_bottom-info">
                                            <p className="subject-property-info_title">
                                                Property Address:
                                            </p>

                                            <p className="subject-property-info_desc blue_desc">
                                                {singleOrder?.report?.address} {singleOrder?.report?.city} {singleOrder?.report?.state}
                                            </p>
                                        </div>

                                        <div className="subject-property-info">
                                            <p className="subject-property-info_title">
                                                Details:
                                            </p>

                                            <p className="subject-property-info_desc">
                                                {singleOrder?.report?.bedrooms} beds
                                                / {singleOrder?.report?.bathrooms} baths
                                                / {singleOrder?.report?.livingArea} Sqft.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="active-available">
                                        <p className="active-available-title">Comparable Rental Properties</p>
                                        {singleOrder?.report?.firstListings.length > 0 ? (
                                            <div>
                                                {width > 767 ? (
                                                    <div className="table">
                                                        {singleOrder?.report?.firstListings?.map(
                                                            (
                                                                {
                                                                    imgSrc,
                                                                    streetAddress,
                                                                    city,
                                                                    state,
                                                                    zipcode,
                                                                    bedrooms,
                                                                    bathrooms,
                                                                    livingArea,
                                                                    price,
                                                                    distance,
                                                                    // comps,
                                                                },
                                                                index,
                                                            ) => (
                                                                <div className="table-body-row" key={index}>
                                                                    {!imgSrc ? (
                                                                        <div
                                                                            className="table-body-cell body-cell_no_image">
                                                                            <img src={Svg.NoImageTemp} alt="photo" />
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className="table-body-cell body-cell_image">
                                                                            <img src={imgSrc} alt="photo" />
                                                                        </div>
                                                                    )}

                                                                    <div className="table-body-cell cell-address">
                                                                        <p>{streetAddress}</p>

                                                                        <p>
                                                                            {city} {state} {zipcode}
                                                                        </p>
                                                                    </div>

                                                                    <div className="table-body-cell cell-rooms">
                                                                        <p>{bedrooms} Beds/{bathrooms} Bath</p>

                                                                        <p>{livingArea} Sqft.</p>
                                                                    </div>

                                                                    <div className="table-body-cell cell-rent">
                                                                        <p>Monthly Rent</p>


                                                                        <p> {price}</p>
                                                                    </div>

                                                                    <div className="table-body-cell cell-dist">
                                                                        <p>Distance from Subject </p>

                                                                        <p>{distance} miles</p>
                                                                    </div>
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '8px',
                                                        }}>
                                                        {singleOrder?.report?.firstListings?.map((singleField, index) => (
                                                            <CardReport singleField={singleField} key={index} />
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <p className="no-data-list">No Data</p>
                                        )}
                                    </div>

                                    <div className="average-pricing-block">
                                        <p className="average-pricing-title">
                                            Subject Property Summary
                                        </p>

                                        <div className="average-pricing-header">
                                            <p className="average-pricing-header-text">Average Monthly Pricing</p>
                                            <p className="average-pricing-header-text ">{singleOrder?.report?.averagePricing}</p>
                                        </div>

                                        <div className="average-pricing-header-white">
                                            <p className="average-pricing-header-text">Monthly Short-Term Premium</p>
                                            <p className="average-pricing-header-text ">{singleOrder?.report?.shortTermPremium}</p>
                                        </div>
                                        <div className="average-pricing-header">
                                            <p className="average-pricing-header-text">Monthly Furniture & Appliance
                                                Rental</p>
                                            <p className="average-pricing-header-text ">{singleOrder?.report?.furnitureRental}</p>
                                        </div>
                                        <div className="average-pricing-header-white">
                                            <p className="average-pricing-header-text">Monthly Utilities(included)</p>
                                            <p className="average-pricing-header-text ">With cap of $500.00</p>
                                        </div>
                                        <div className="average-pricing-header">
                                            <p className="average-pricing-header-text">Total Monthly Rent</p>
                                            <p className="average-pricing-header-text ">{singleOrder?.report?.totalMonthlyRent}</p>
                                        </div>
                                    </div>

                                    <p className="fair-rental-value-footer">
                                        Fair Rental Value (FRV)
                                        <span style={{
                                            fontSize: '27px',
                                            textDecorationLine: 'underline',
                                        }}> {singleOrder?.report?.rentValueEstimate}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="rental-screen-2">
                                <div className="rental-container">
                                    {/*<div className="rental-screen-2-header-back revers">*/}
                                    {/*  <img src={Images.ReadyBack} alt="back" />*/}
                                    {/*</div>*/}

                                    <div className="disclosure-block">
                                        <h4 className="disclosure-title">
                                            DISCLOSURE
                                        </h4>

                                        <p className="disclosure-text">
                                            Our AI-powered FRV (Fair Rental Value) Generator employs a sophisticated
                                            Mathematical Algorithm to
                                            precisely calculate
                                            the FRVs, leveraging data from various reliable sources. The AI generator
                                            meticulously analyzes
                                            rentals in your area
                                            to derive the most accurate Fair Rental Value for your claim.
                                        </p>
                                    </div>

                                    <div className="expenses-wrapper">
                                        <p className="expenses-wrapper-title">
                                            Additional Comparables used for generating report
                                        </p>
                                        {singleOrder?.report?.secondListings.map(
                                            ({
                                                 streetAddress,
                                                 city,
                                                 state,
                                                 zipcode,
                                                 bedrooms,
                                                 bathrooms,
                                                 livingArea,
                                                 price,
                                             }, index) => (
                                                <div className="expenses-block" key={index}>
                                                    <p className="expenses-text expenses-text_address">
                                                        {streetAddress} {city} {state} {zipcode}
                                                    </p>

                                                    <p className="expenses-text">
                                                        {bedrooms} beds/{bathrooms} bath
                                                    </p>

                                                    <p className="expenses-text">{livingArea} Sqft</p>

                                                    <p className="expenses-text expenses-rent">{price} Rent</p>
                                                </div>
                                            ),
                                        )}
                                    </div>

                                    <div className="rental-market-wrapper">
                                        <div>
                                            <h4 className="rental-market_title">
                                                Rental Market Summary
                                            </h4>

                                            <ul className="disclosure-box">
                                                <li className="disclosure-list">
                                                    <span className="disclosure-list" />
                                                    Number of Listings: {singleOrder?.report?.numListings}
                                                </li>

                                                <li className="disclosure-list">
                                                    Average Price per Sqft: {singleOrder?.report?.rentPerSqf}
                                                </li>

                                                {/*<li>Additional costs for short term rent/month {singleOrder?.report?.monthlyCharge}</li>*/}
                                                {/*<li> Other Source Zestimate*/}
                                                {/*  <br />{`-> median price ${singleOrder?.report?.zestimate}`}*/}
                                                {/*  <br /> {`-> max price ${singleOrder?.report?.rentHigh}`}*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                        {/*Per Squer Feet For Houses Equals= {singleOrder?.report?.rentPerSqf} per/sqf. For Short term Rent, Month-Over-Month*/}
                                        {/*Charge {singleOrder?.report?.monthlyCharge} May Apply. Other Source Zestimate for Median Price is*/}
                                        {/*{ ` ${singleOrder?.report?.zestimate}`}. And Max is {singleOrder?.report?.rentHigh}*/}
                                    </div>

                                    <div className="evaluation-wrapper">
                                        <p className="evaluation">
                                            {`This evaluation provides an estimated value based on similar housing in the area, with the
                      home's amenities factored into the FRV. Availability must be confirmed with current landlords
                      for the desired move-in dates. The pricing excludes any deposits or fees that the landlord may
                      charge, and Supplier Management Fees are yet to be determined.`}
                                        </p>

                                        <p className="evaluation evaluation-margin">
                                            {`ClaimFRV offers an UNBIASED opinion of value. This ensures that the report is impartial and
                      not influenced by ClaimFRV's interests. ClaimFRV has no direct or indirect, current, or future
                      interest in the property and will not gain any benefit from future events related to this report.`}
                                        </p>
                                    </div>

                                    <div className="footer-block">
                                        <div>
                                            <p className="patent-pending">Patent Pending</p>
                                            <Svg.LogoShort className="header-logo-svg" />
                                        </div>

                                        <div className="footer-social-wrapper">
                                            <div className="footer-social-box">
                                                <Svg.Sms className="header-logo-svg" />

                                                <a href="mailto:claimfrv@gmail.com" target="_blank"
                                                   rel="noopener noreferrer">
                                                    claimfrv@gmail.com
                                                </a>
                                            </div>

                                            <div className="footer-social-box">
                                                <Svg.GlobalSvg className="header-logo-svg" />
                                                <a href="https://claimfrv.com/" target="_blank"
                                                   rel="noopener noreferrer">
                                                    www.claimfrv.com
                                                </a>
                                            </div>

                                            <div className="footer-social-box">
                                                <Svg.CallSvg className="header-logo-svg" />
                                                <a href="tel:+8338922970" target="_blank" rel="noopener noreferrer">
                                                    (833) 892-2970
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="rental-screen-2-header-back">*/}
                                    {/*  <img src={REPORT_IMAGE_SOURCE} alt="photo" />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                        <div className="button-svg-content">
                            <Svg.CloseSvg className="button-svg" onClick={() => navigate('/')} />

                            <ButtonMain
                                text="Download"
                                onClick={() => handleDownload(singleOrder?.pdfFile)}
                                loading={downloadLoad}
                                className="rental-download-button"
                                startAdornment={<Svg.Download2Svg />}
                            />
                        </div>

                        <ButtonMain
                            text="Download"
                            onClick={() => handleDownload(singleOrder?.pdfFile)}
                            loading={downloadLoad}
                            className="rental-download-button-mobile"
                            startAdornment={<Svg.Download2Svg />}
                        />
                    </div>
                ) : (
                    <div className="rental-no-data-wrapper">
                        <Svg.NoDataSvg className="rental-no-data-svg" />

                        <h4 className="rental-no-data-text">No Such Report Yet</h4>

                        <ButtonMain
                            text="Get Your FRV Report Now"
                            className="rental-no-data-button"
                            startAdornment={<Svg.Listing />}
                            onClick={() => navigate('/order-now')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
